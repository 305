import { Buffer } from 'buffer';

// savannadevs-auth API URL
const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;

const apiAuthProvider = {
    isAuthenticated: false,

    signin(email: string, password: string, callback: any) {
        /**
         * makes a call to the login backend provider to
         * sign in a user by email and password.
         * Expected response contains a 'user' object.
         *
         * returns response object if successful
         * returns response code 401 if unauthorized
         * returns response code 400 if bad request or other error
         */
        let encodedauth = Buffer.from(email + ':' + password).toString('base64');
        let requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encodedauth
            },
        }
        fetch(AUTH_API_URL + '/login', requestOptions
        ).then(res => {
            if (!res.ok) {
                if (res.status === 401) {
                    apiAuthProvider.isAuthenticated = false;
                    return res.status;
                } else {
                    throw new Error("HTTP error, status = " + res.status);
                }
            }
            return res.json();
        }).then(data => {
            apiAuthProvider.isAuthenticated = (typeof data === 'object' && 'user' in data);
            callback(data);
        }).catch(_ => {
            callback(400);
        });
    },
    signInGoogleUser(jwtToken: string, callback: any) {
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({'credential': jwtToken})
        };
        fetch(AUTH_API_URL + '/login_with_google', requestOptions
        ).then(res => {
            if (!res.ok) {
                apiAuthProvider.isAuthenticated = false;
                throw new Error("HTTP error, status = " + res.status);
            }
            return res.json();
        }).then (data => {
            if ('user' in data) {
                apiAuthProvider.isAuthenticated = true;
                callback(data);
            } else {
                apiAuthProvider.isAuthenticated = false;
                callback(400);
            }
        }).catch (_ => {
            callback(400);
        })
    },
    signout(access_token: any, callback: any) {
        let randomTimeout = Math.random() * (450 - 100) + 100;
        if (!access_token) {
            setTimeout(callback, randomTimeout, 400);
            return;
        }
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            }
        }
        fetch(AUTH_API_URL + '/revoke_token', requestOptions
        ).then(res => {
            if (!res.ok) {
                if (res.status === 401) {
                    apiAuthProvider.isAuthenticated = false;
                    return res.status;
                } else {
                    throw new Error("HTTP error, status = " + res.status);
                }
            }
            return res.status;
        }).then(status_code => {
            apiAuthProvider.isAuthenticated = false;
            setTimeout(callback, randomTimeout, status_code);
        }).catch((_: any) => {
            setTimeout(callback, 100, 400);
        });
    },
    refresh_token(access_token: string, refresh_token: string, callback: any) {
        fetch(AUTH_API_URL + '/refresh_token', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'access_token': access_token,
                'refresh_token': refresh_token,
            })
        }).then(res => {
            if (!res.ok) {
                if (res.status === 401) {
                    apiAuthProvider.isAuthenticated = false;
                    return res.status;
                } else {
                    throw new Error("HTTP error, status = " + res.status);
                }
            }
            return res.json();
        }).then(data => {
            apiAuthProvider.isAuthenticated = (typeof data === 'object' && 'access_token' in data);
            callback(data);
        }).catch(_ => {
            callback(400);
        });
    },
    signup(email: string, password: string, callback: any) {
        fetch(AUTH_API_URL + '/register', {
			method: 'POST', headers: { 'Content-Type': 'application/json'},
			body: JSON.stringify({ 'email': email, 'username': email, 'password': password})
		}).then(res => {
            callback(res.status);
		}).catch(e => {
            callback(500);
        });
    },
    getuser(access_token: string, callback: any) {
        fetch(AUTH_API_URL + '/user/', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(res => {
            if (!res.ok) {
                if (res.status === 401) {
                    apiAuthProvider.isAuthenticated = false;
                    return res.status;
                } else {
                    throw new Error("HTTP error, status = " + res.status);
                }
            } else {
                return res.json();
            }
        }).then(data => {
            apiAuthProvider.isAuthenticated = (typeof data === 'object' && 'username' in data);
            callback(data);
        }).catch(_ => {
            callback(400);
        });
    },
    request_password_reset_token(email: string, callback: (r: number, s: string) => void) {
        fetch(AUTH_API_URL + '/reset_password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'email': email })
        }).then(res => {
            res.text().then((data) => {
                callback(res.status, data);
            });
        }).catch(_ => {
            callback(400, 'error');
        });
    },
    reset_password(token: string, password: string, callback: any) {
        fetch(AUTH_API_URL + '/reset_password', {
			method: 'PUT', headers: { 'Content-Type': 'application/json'},
			body: JSON.stringify({ 'token': token, 'password': password})
		}).then(res => {
            res.text().then((data) => {
                callback(res.status, data);
            });
		}).catch((_) => {
            callback(400, 'error');
        });
    }
};

export { apiAuthProvider };
