import React from 'react';
import './App.css';
import { AuthStatus, Login, Register, RequireAuth, RequestPasswordResetToken, ResetPassword, useAuth, VerifyEmail } from './AuthComponents';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { SymbolSearch } from './SymbolSearchBar';


const API_URL = process.env.REACT_APP_API_URL;


interface IProps {
  open?: boolean;
  title: string;
  children: React.ReactNode;
  onOpen?: () => void;
}

export const Collapsible: React.FC<IProps> = ({ open, children, title, onOpen }) => {
  const [isOpen, setIsOpen] = React.useState(open);

  const handleFilterOpening = (opened: boolean) => {
    setIsOpen(prev => !prev);
    if (opened && typeof onOpen === 'function') {
      onOpen();
    }
  };

  return (
    // style={{backgroundColor: '#dedbd5'}}
      <div className="mx-auto my-0 py-0" >
          <div className="row rounded py-0 mb-2 mt-1">
              <h5 className="justify-content-between my-0 py-0" style={{width:"85%"}}>{title}</h5>
              <button type="button" className="btn btn-sm my-0 py-0" onClick={() => handleFilterOpening(!isOpen)} style={{width:"15%"}}>
                  {isOpen ? (
                  <i className="bi bi-chevron-up fs-6"></i>
                  ) : (
                    <i className="bi bi-chevron-down fs-6"></i>
                  )}
              </button>
          </div>
      {!isOpen ? <></> :
          (<div>
              <div>{isOpen && <div className="">{children}</div>}</div>
          </div>)}
      </div>
  );
};

function NavBar() {
  return (
    <nav className='navbar navbar-expand-md'>
      <div className='container-fluid'>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          </ul>
          <form className='d-flex'>
            <AuthStatus/>
          </form>
        </div>
      </div>
    </nav>
  );
}

interface TrackedTicker {
  'ticker': string;
  '10k': boolean;
  '10q': boolean;
  '8k': boolean;
  '4': boolean;
}

interface TrackedTickerComponentProps extends TrackedTicker {
  onDelete: () => void;
}

function TrackedTickerComponent(props: TrackedTickerComponentProps) {
  let auth = useAuth();
  let navigate = useNavigate();

  const [tenk, setTenk] = React.useState(props['10k']);
  const [tenq, setTenq] = React.useState(props['10q']);
  const [eightk, setEightk] = React.useState(props['8k']);
  const [four, setFour] = React.useState(props['4']);

  const TENK_BACKGROUND = '#cfe2ff';
  const TENQ_BACKGROUND = '#af75ba';
  const EIGHTK_BACKGROUND = '#cfe2ff';
  const FOUR_BACKGROUND = '#cfe2ff';

  const [latestFilings, setLatestFilings]: any = React.useState({'filings': []});

  function fetchLatestFilings() {
    if (props.ticker === '') return;
    if (latestFilings['filings'].length > 0) return;

    fetch(
      `${API_URL}/latestfilings?ticker=${props.ticker}`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(res => {
        if (!res.ok) {
          if (res.status === 401) {
            auth.refresh_token((code: number) => {
              if (typeof code !== 'number' || code !== 200) {
                auth.signout((_: number) => {});
                navigate('/login');
              } else if (code === 200) {
                fetchLatestFilings();
              }
            });
          } else {
            throw new Error('error');
          }
        }
        return res.json()
      }).then(data => {
        data.filings.sort((a: any, b: any) => {
          let d1 = new Date(a.reporting_date.substr(0, 4), parseInt(a.reporting_date.substr(4, 2)) - 1, a.reporting_date.substr(6, 2));
          let d2 = new Date(b.reporting_date.substr(0, 4), parseInt(b.reporting_date.substr(4, 2)) - 1, b.reporting_date.substr(6, 2));
          return d2.getTime() - d1.getTime();
        });
        setLatestFilings((curFilings: any) => {return {...curFilings, filings: data.filings}});
      }).catch(_ => {});
  }

  function deleteTicker() {
    fetch(
      `${API_URL}/untrack?ticker=${props.ticker}`,
      {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(res => {
        if (!res.ok) {
          if (res.status === 401) {
            auth.refresh_token((code: number) => {
              if (typeof code !== 'number' || code !== 200) {
                auth.signout(() => {});
                navigate('/login');
              }
            });
          } else {
            throw new Error('error');
          }
        }
        return res.json()
      }).then(_ => {
        props.onDelete();
      }).catch(err => {});
  }

  function toggleNotification(form: string, value: boolean) {
    fetch(
      `${API_URL}/track`,
      {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({'ticker': props.ticker, 'form': form, 'value': value})
      })
      .then(res => {
        if (!res.ok) {
          if (res.status === 401) {
            auth.refresh_token((code: number) => {
              if (typeof code !== 'number' || code !== 200) {
                auth.signout(() => {});
                navigate('/login');
              }
            });
          } else {
            throw new Error('error');
          }
        }
        return res.json()
      }).then(data => {
        if (form === '10k') {
          setTenk(value);
        } else if (form === '10q') {
          setTenq(value);
        } else if (form === '8k') {
          setEightk(value);
        } else if (form === '4') {
          setFour(value);
        }
      }).catch(err => {});
  }

  function getlabelbackground(form: string) {
    form = form.toLowerCase();
    if (form === '10-k') {
      return TENK_BACKGROUND;
    } else if (form === '10-q') {
      return TENQ_BACKGROUND;
    } else if (form === '8-k') {
      return EIGHTK_BACKGROUND;
    } else if (form === '4') {
      return FOUR_BACKGROUND;
    }
  }

  return (
    <div className='border-bottom container-fluid mx-1 pt-2'>
      <div className='row ps-0 mx-0 pe-2'>
        <div className='col-3 border-end p-0 my-0'>
          <div className='my-0 py-0'><p className='text-uppercase fw-bold py-0 my-0'>{props.ticker}</p></div>
        </div>
        <div className='col-8 px-0 mx-0'>
          <div className='d-inline-flex gap-2 gap-md-3 gap-lg-5 my-0 py-0'>
            {[['10K', tenk], ['10Q', tenq], ['8K', eightk], ['4', four]].map((f: any, index) => {
              let classExt = f[1] ? 'btn-primary' : 'bg-secondary';
              return (
                <div key={index} className='my-0 py-0'><p className={'btn btn-sm my-0 py-0 text-uppercase '.concat(classExt)}
                  onClick={() => {toggleNotification(f[0].toLowerCase(), !f[1])}}
                >{f[0]}</p></div>
              );
            })}
          </div>
        </div>
        <div className='col-1'>
            <button className='btn btn-sm py-0 btn-danger' onClick={deleteTicker} ><i className='bi bi-trash'/></button>
        </div>
      </div>
      <div className='row ps-0 mx-0 pe-2 pt-2 mb-1'>
        <Collapsible open={false} title={`latest filing: `.concat(latestFilings['filings'][0] !== undefined ? latestFilings['filings'][0].reporting_date : '')} onOpen={fetchLatestFilings}>
          <div>
            <div className='row'>
              {latestFilings['filings'].map((f: any, index: number) => {
                let dd = new Date(f.reporting_date.substr(0, 4), parseInt(f.reporting_date.substr(4, 2)) - 1, f.reporting_date.substr(6, 2));
                return (
                <div className='row mx-auto my-0 py-0 my-1' key={index}>
                  <div className='col-3'>
                    <p className={`btn btn-sm rounded-pill mx-1 my-0 py-0 fs-6`} style={{backgroundColor: getlabelbackground(f.form)}}>{f.form.replace('-', '')}</p>
                  </div>
                  <div className='col-4 my-0 py-0'>
                    <div className='row my-0 py-0 px-0'><p className='fs-6 fw-bold my-0 mx-0 px-0 py-0'>{dd.toDateString().substring(3)}</p></div>
                  </div>
                  <div className='col-5'><a className='btn btn-sm rounded-pill fs-6 bg-success-subtle' target='_blank' href={f.url}>View</a>
                  </div>
                </div>
              )})}
            </div>
          </div>
        </Collapsible>
      </div>
    </div>
  );
}

function FormDefinitions() {
  return (<div>
    <h2 className='my-2'>Form Types</h2>
    <div>
      <h3>10-K</h3>
      <p>An annual report required by the SEC, that gives a comprehensive summary of a company's financial performance. The 10-K includes information such as company history, organizational structure, executive compensation, equity, subsidiaries, and audited financial statements, among other information.</p>
    </div>
    <div>
      <h3>10-Q</h3>
      <p>A quarterly report that contains similar information to the 10-K, but is generally less detailed and only includes unaudited financial statements.</p>
    </div>
    <div>
      <h3>8-K</h3>
      <p>A report of significant events or changes that are important to shareholders, such as acquisitions, disposals, changes in management, amendments to articles of incorporation, or other material events that investors should be aware of.</p>
    </div>
    <div>
      <h3>4</h3>
      <p>4 is a form that must be filed with the SEC whenever there is a material change in the holdings of company insiders.</p>
    </div>
  </div>);
}

function Profile() {
  let navigate = useNavigate();
  let auth = useAuth();

  const [tickers, setTickers] = React.useState({'tickers': []});

  React.useEffect(() => {
    fetchTrackedTickers();
  }, [auth.user.username]);

  function fetchTrackedTickers() {
    fetch(
      `${API_URL}/tracked`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        if (!res.ok) {
          if (res.status === 401) {
            auth.refresh_token((code: number) => {
              if (typeof code !== 'number' || code !== 200) {
                auth.signout((_: number) => {
                  navigate('/login');
                });
              } else if (code === 200) {
                fetchTrackedTickers();
              }
            });
          } else {
            throw new Error('error');
          }
        }
        return res.json()
      }).then(data => {
        setTickers(prevTickers => {return {...prevTickers, tickers: data.tracked}});
      }).catch(err => {});
  };

  function trackTicker(ticker: string) {
    if (ticker === '') return;
    if (tickers['tickers'].find((t: TrackedTicker) => t.ticker === ticker)) return;
    fetch(
      `${API_URL}/track`,
      {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({'ticker': ticker})
      })
      .then(res => {
        if (!res.ok) {
          if (res.status === 401) {
            auth.refresh_token((code: number) => {
              if (typeof code !== 'number' || code !== 200) {
                auth.signout((code: number) => {});
                navigate('/login');
              } else {
                throw new Error('error');
              }
            });
          } else {
            throw new Error('error');
          }
        }
        return res.json()
      }).then(data => {
        setTickers({...tickers, 'tickers': tickers['tickers'].concat(data)});
      }).catch(_ => {});
  }

  return (
    <div>
      <h1 className='pt-5'>{auth.user.username}</h1>
      <div className='pt-5'>
        <div><SymbolSearch onSearch={(ticker: string) => {
          trackTicker(ticker);
          }} />
        </div>
        <div>
          <h3>tracked tickers</h3>
          <div className='row'>
            <div className='col-12 mx-0 px-0'>
              <ul className='mx-0 px-0'>
                {tickers['tickers'] !== undefined && tickers['tickers'].length > 0 &&
                  tickers['tickers'].sort((a: TrackedTicker, b: TrackedTicker) => a.ticker.localeCompare(b.ticker)).map((ticker: TrackedTicker, index: number) => (
                  <div key={index} style={{backgroundColor: index % 2 === 0 ? '#a6bfbe': '#fff'}}>
                    <TrackedTickerComponent {...ticker} onDelete={() => {
                      setTickers({...tickers, 'tickers': tickers['tickers'].filter((t: TrackedTicker) => t.ticker !== ticker.ticker)});
                    }} />
                  </div>
                ))}
              </ul>
              <div className='pt-4'>
                <div className='row my-2 w-75 mx-auto'>
                  <div className='col-6'><p className='w-50 py-1 rounded'>notification status</p></div>
                  <div className='col'><p className='rounded mx-lg-5 bg-primary' onClick={()=>{}}>on</p></div>
                  <div className='col'><p className='rounded mx-lg-5 bg-secondary' onClick={()=>{}}>off</p></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-3'><FormDefinitions/></div>
      </div>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <NavBar/>
      <div className='container'>
        <Routes>
          <Route path="/" element={<RequireAuth><Profile /></RequireAuth>} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path='/reset_password' element={<RequestPasswordResetToken/>}/>
          <Route path='/submit_reset_password' element={<ResetPassword/>}/>
          <Route path='/verify_email' element={<VerifyEmail/>}/>
        </Routes>
      </div>
    </div>
  );
}

export default App;
