import React, { useState} from "react";
import tickers from './tickers.json';

interface Suggestion {
    text: string;
    score: number;
}

interface SearchBarProps {
    onSearch: (value: string) => void;
}

export const SymbolSearch: React.FC<SearchBarProps> = (props: SearchBarProps) => {
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);

    function getLevenshteinDistance(a: string, b: string) {
        const m = a.length;
        const n = b.length;
        const d = Array(m + 1)
          .fill(null)
          .map(() => Array(n + 1).fill(null));

        for (let i = 0; i <= m; i++) {
          d[i][0] = i;
        }

        for (let j = 0; j <= n; j++) {
          d[0][j] = j;
        }

        for (let j = 1; j <= n; j++) {
          for (let i = 1; i <= m; i++) {
            const cost = a[i - 1] === b[j - 1] ? 0 : 1;
            d[i][j] = Math.min(
              d[i - 1][j] + 1, // deletion
              d[i][j - 1] + 1, // insertion
              d[i - 1][j - 1] + cost // substitution
            );
          }
        }

        return d[m][n];
    }

    function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        setSearchTerm(value);

        if (value.length < 1) {
            setSuggestions([]);
            return;
        }

        const scoredSuggestions = tickers.filter(
            (t: string) => t.startsWith(value.toLocaleLowerCase())
        ).map((word: string) => {
            const distance = getLevenshteinDistance(word, value);
            const score = distance === 0 ? 3 : distance <= 2 ? 2 : 1;
            return { text: word, score: score };
        })
        .filter((suggestion: any) => suggestion.score > 0)
        .sort((a: any, b: any) => b.score - a.score)
        .slice(0, 6);
        setSuggestions(scoredSuggestions);
    }

    return (
        <div className="mx-auto my-5">
            <div className="row w-lg-75 mx-auto">
                <div className="col-8 position-relative">
                    <input
                    className='shadow border rounded py-2 px-2 w-75 position-absolute end-0 fs-4 fw-bold'
                    type="text"
                    value={searchTerm} onChange={handleInputChange}
                    placeholder='stock symbol'
                    onKeyDown={(e: any) => {
                        if (e.keyCode === 13) { // enter key
                            setSearchTerm(suggestions[selectedSuggestionIndex].text);
                            setSelectedSuggestionIndex(0);
                            setSuggestions([]);
                            props.onSearch(suggestions[selectedSuggestionIndex].text);
                        } else if (e.keyCode === 38) { // up arrow
                            setSelectedSuggestionIndex(selectedSuggestionIndex - 1);
                        } else if (e.keyCode === 40) {  // down arrow
                            setSelectedSuggestionIndex(selectedSuggestionIndex + 1);
                        }
                    }}/>
                </div>
                <div className="col-4">
                    <button className='btn btn-primary hover:bg-gray-400 text-white w-75 fs-4 fw-bold'
                        onClick={() => { props.onSearch(searchTerm);}}>
                        track
                    </button>
                </div>
            </div>
            <div className="row w-75 mx-auto bg-danger">
                <div className="col-8 position-relative">
                    <ul className="list-group w-75 px-2 position-absolute end-0 mt-2 pt-2">
                        {suggestions.map((suggestion, index) => (
                        <li
                            key={suggestion.text}
                            onClick={(e: any) => {
                                setSearchTerm(suggestion.text);
                                setSuggestions([]);
                                setSelectedSuggestionIndex(0);
                            }}
                            // highlight list item if mouse is hovering over it
                            onMouseOver={(e: any) => {
                                setSelectedSuggestionIndex(index);
                            }}
                            className={'list-group-item px-0 mx-0 w-100 py-1 fs-4 fw-bold ' + (selectedSuggestionIndex === index ? 'bg-primary': '') +
                            (index === 0 ? ' rounded-top' : '') + (index === suggestions.length - 1 ? ' rounded-bottom' : '')}
                            style={{listStyleType: "none"}}
                        >{suggestion.text}</li>
                        ))}
                    </ul>
                </div>
                <div className="col-4"></div>
            </div>
        </div>
    );
};


export function SearchBar(props: any) {
	async function searchSymbol(symbol: string) {
		let url = '/api/stocks/?symbol=' + symbol;
		fetch(url).then(res => res.json()).then(
			data => {
				props.setStock(data);
			}
		);
	}

	return (
		<SymbolSearch onSearch={(symbol: string) => {
			searchSymbol(symbol)
		}}/>
	);
}
